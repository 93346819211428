.tooltip-message-wrapper {
  background-color: var(--main-bg);
  border-radius: 10px;
  padding: 5px 10px;
  z-index: 1050;
  border: 1px solid var(--gray);
  position: relative;
  opacity: 0.7;
}

.tooltip-message-triangle {
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translate(-50%, 0);
  -webkit-transform: translate(-50%, 0);
  height: 10px;
  width: 10px;
  background-color: var(--main-bg);
  rotate: 45deg;
}

.tooltip-message-close {
  position: absolute;
  right: 12px;
  top: 12px;
  cursor: pointer;
}

.tooltip-message-content {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.tooltip-message-content-header,
.tooltip-message-content-text {
  text-align: center;
}

.tooltip-message-content-header span{
  font-weight: 600;
  font-size: 18px;
  color: var(--body-text);
}

.tooltip-message-content-text span {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: var(--body-text);
}
