.MainBlock-wrapper {
  width: 100%;
  /* height: calc(100vh - 142px); */
  height: calc(100vh - 56px);
  display: flex;
  padding: var(--padding-s-8) var(--padding-m-24) var(--padding-m-24)
    var(--padding-m-24);
  gap: var(--padding-xxm-16);
  background-color: var(--white);
  position: relative;
  /* overflow:hidden */
}

.MainBlock-wrapper-left {
  /* width: 30%; */
  /* position: fixed; */
  position: absolute;
  /* height: calc(100% - 174px); */
  height: calc(100% - 66px);
  overflow: hidden;
  z-index: 1008;
  width: 30%;
}

.MainBlock-wrapper-left-isNarrow {
  width: 160px;
}

.MainBlock-wrapper-left-isNotNarrow {
  width: 30%;
}

.MainBlock-wrapper-left-closed {
  -webkit-transition: left 2s, width 2s;
  -moz-transition: left 2s, width 2s;
  -o-transition: left 2s, width 2s;
  transition: left 2s, width 2s;
}

.LeftMainBlock-wrapper {
  overflow-y: auto;
  height: 100%;
  overflow-y: scroll;
  padding-right: 17px; /* Increase/decrease this value for cross-browser compatibility */
  box-sizing: content-box; /* So the width will be 100% + 17px */
}

.MainBlock-wrapper-right {
  width: calc(70% - 16px);
  margin-left: calc(30% + 16px);
  overflow: hidden;
  -webkit-transition: margin 2s, width 2s;
  -moz-transition: margin 2s, width 2s;
  -o-transition: margin 2s, width 2s;
  transition: margin 2s, width 2s;
}

.MainBlock-wrapper-right-narrow {
  width: calc(100% - 176px);
  margin-left: 160px;
  overflow: hidden;
  -webkit-transition: margin 2s, width 2s;
  -moz-transition: margin 2s, width 2s;
  -o-transition: margin 2s, width 2s;
  transition: margin 2s, width 2s;
}

.MainBlock-wrapper-right-narrow .forms-pdf-viewer {
  margin: 0 auto;
}

.MainBlock-header-wrapper {
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  color: var(--blue);
  border-top-left-radius: var(--padding-s-8);
  border-bottom: 2px solid var(--blue);
  font-weight: 600;
  font-size: 18px;
}

.MainBlock-wrapper-empty-page {
  /* height: calc(100vh - 70px - 56px - 48px); */
  height: 100%;
  width: 100%;
  border: 1px solid var(--sub-header-bg);
  border-radius: var(--padding-s-8);
}

.MainBlock-wrapper-left-isReducedTitle {
  width: 20%;
}

.MainBlock-wrapper-right-isReducedTitle {
  width: calc(80% - 16px);
  margin-left: calc(20% + 16px);
}
