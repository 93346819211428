/* .LeftMainBlock-main-wrapper {
  position: relative;
} */

.LeftMainBlock-wrapper {
  background-color: var(--white);
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.LeftMainBlock-header-wrapper {
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  color: var(--blue);
  border-bottom: 2px solid var(--blue);
  font-weight: 600;
  font-size: 18px;
}

.MainBlock-wrapper-left-open-btn {
  display: flex;
  background-color: var(--light-gray);
  width: 26px;
  /* height: calc(100% - 24px); */
  height: 100%;
  cursor: pointer;
  z-index: 7;
  position: fixed;
  /* top: 182px; */
}

.MainBlock-wrapper-left-open-btn-opened {
  left: calc(100% - 48px);
  -webkit-transition: left 2s;
  -moz-transition: left 2s;
  -o-transition: left 2s;
  transition: left 2s;
}

.MainBlock-wrapper-left-open-btn-closed {
  left: 24px;
  -webkit-transition: left 2s;
  -moz-transition: left 2s;
  -o-transition: left 2s;
  transition: left 2s;
}

.MainBlock-wrapper-left-opened .LeftMainBlock-main-wrapper {
  padding-bottom: 40px;
}

/* .MainBlock-wrapper-left-open-btn:hover {
  opacity: 0.7;
} */

.MainBlock-wrapper-left-open-btn-wrapper {
  position: relative;
  /* height: calc(100vh - 182px); */
  height: calc(100vh - 120px);
  width: 24px;
}

.MainBlock-wrapper-left-open-btn-wrapper-opened {
  border-left: 1px solid var(--gray);
  -webkit-transition: width 2s;
  -moz-transition: width 2s;
  -o-transition: width 2s;
  transition: width 2s;
}

.MainBlock-wrapper-left-open-btn-wrapper-closed {
  border-right: 1px solid var(--gray);
  -webkit-transition: width 2s;
  -moz-transition: width 2s;
  -o-transition: width 2s;
  transition: width 2s;
}

.MainBlock-wrapper-left-open-btn-content {
  position: absolute;
  top: 50%;
}

.MainBlock-wrapper-left-open-btn-content-left {
  left: calc(100% - 28px);
}
.MainBlock-wrapper-left-open-btn-content-right {
  left: 5px;
}

.MainBlock-wrapper-left-open-btn-content-left svg path {
  transform: rotate(270deg);
  transform-origin: 50% 50%;
}

.MainBlock-wrapper-left-open-btn-content-right svg path {
  transform: rotate(90deg);
  transform-origin: 50% 50%;
}
