.PopUpMessage-popup-wrapper {
  z-index: 1055;
  position: absolute;
  background-color: var(--light-gray);
  border-radius: var(--padding-s-8);
  border: 1px solid var(--border-line);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}

.PopUpMessage-popup-wrapper-box {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
  height: 100%;
}

.PopUpMessage-popup-wrapper-box-onlycontext {
  height: 100%;
}

.PopUpMessage-popup-wrapper-box-onlycontext .PopUpMessage-popup-content {
  margin: auto 0;
  height: 100%;
}

.PopUpMessage-popup-close {
  position: absolute;
  right: 12px;
  top: 12px;
  cursor: pointer;
}

.PopUpMessage-popup-content,
.PopUpMessage-popup-title {
  display: flex;
  justify-content: center;
  align-items: center;
}

.PopUpMessage-popup-title {
  font-size: 18px;
}

.PopUpMessage-popup-content {
  font-size: 14px;
}

.PopUpMessage-popup-content > div {
  padding: 16px;
  flex: 1;
  text-align: center;
}
