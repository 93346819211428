.alert-message-wrapper {
  position: fixed;
  width: 300px;
  background-color: var(--main-bg);
  border-radius: 10px;
  padding: 40px 35px;
  z-index: 1055;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  border: 1px solid var(--gray);
}

.alert-message-close {
  position: absolute;
  right: 12px;
  top: 12px;
  cursor: pointer;
}

.alert-message-content {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.alert-message-content-header,
.alert-message-content-text {
  text-align: center;
}

.alert-message-content-header span {
  font-weight: 600;
  font-size: 18px;
  color: var(--body-text);
}

.alert-message-content-text span {
  font-weight: 400;
  font-size: 14px;
  line-height: 16.94px;
  color: var(--body-text);
}
