@media screen and (min-width: 769px) and (max-width: 1024px) {
  .MainPage-header-wrapper-initial .MainPage-header-left {
    width: 100%;
  }
  /* .MainPage-header-wrapper {
    flex-direction: column;
  } */

  .MainPage-header-wrapper .MainPage-header-right,
  .MainPage-header-wrapper .MainPage-header-left {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .MainPage-header-wrapper .base-input-container input {
    padding: var(--padding-xxm-16);
  }
  .MainPage-header-wrapper-initial .MainPage-header-left {
    width: 100%;
  }
}

@media screen and (max-width: 1024px) {
  .MainPage-header-wrapper .MainPage-header-left {
    width: calc(100% - 104px) !important;
  }

  .MainPage-header-wrapper .MainPage-header-right {
    width: 104px !important;
  }
}

@media screen and (min-width: 1025px) {
  .MainPage-header-right-NotInit {
    width: 70%;
  }
}
