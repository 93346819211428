body {
  font-family: Inter;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

label {
  margin-bottom: 0;
}

:root {
  --black: black;
  --main-bg: #141313;
  --second-bg: #2b2b2b;
  --lightslaygray-bg: #778899;
  --body-text: #f2f2f2;
  --second-body-text: rgba(242, 242, 242, 0.8);
  --gray: #908f8f;
  --light-gray: #f9f9f9;
  --blue: #2078bd;
  --text-blue: #001296;
  --light-blue-bg: #b3ccf1b8;
  --sub-header-bg: #dcdde3;
  --light-blue: rgba(192, 222, 244, 0.8);
  --light-blue100: rgba(192, 222, 244, 1);
  --white: #ffffff;
  --third-bg: #e9e9e9;
  --red: #ff221c;
  --green: #28a745;
  --light-green: #b6eec3;
  --yellow: #ffc805;
  --yellow-Bg: rgba(241, 241, 149, 0.6);
  --secondYellow: #ffbf44;

  --mobile-main-btn-height: 45px;

  --border-line: #2b2b2b;
  --border-focused: #908f8f;

  --padding-l-40: 40px;
  --padding-m-24l-36: 36px;
  --padding-xl-32: 32px;
  --padding-xxl-28: 28px;
  --padding-m-24: 24px;
  --padding-xm-20: 20px;
  --padding-xxm-16: 16px;
  --padding-xxxm-12: 12px;
  --padding-s-8: 8px;
  --padding-xs-4: 4px;
  --padding-xxs-2: 2px;
}

#pdfReader {
  margin: 0;
  display: flex;
  flex-direction: column;
}
