.BottomTitleBlock-wrapper {
  color: var(--blue);
  position: absolute;
  bottom: 8pt;
  left: 17pt;
  max-width: calc(100% - 8px);
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  font-size: 8pt;
}
