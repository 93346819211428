.print-popup-wrapper {
  position: fixed;
  width: 560px;
  background-color: var(--light-gray);
  border-radius: 10px;
  padding: 40px 15px 25px;
  z-index: 1055;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  border: 3px solid var(--blue);
  display: flex;
  flex-direction: column;
  gap: var(--padding-xxl-28);
}

.print-popup-close {
  position: absolute;
  right: 12px;
  top: 12px;
  cursor: pointer;
}

.print-popup-content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.print-popup-content-header {
  text-align: center;
}

.print-popup-content-header span {
  font-weight: 600;
  font-size: 18px;
  color: var(--body-text);
}

.print-popup-content-wrapper {
  display: flex;
  gap: 16px;
}

.print-popup-content-text-wrapper {
  max-height: 140px;
  overflow-y: auto;
  padding: 5px 0;
}

.print-popup-content-text-wrapper span {
  font-weight: 400;
  font-size: 14px;
  line-height: 16.94px;
  color: var(--body-text);
}

.print-popup-content-content-wrapper {
  display: block;
  width: calc(100% - 40px);
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  font-size: 13px;
  height: var(--padding-xm-20);
  line-height: var(--padding-xm-20);
  gap: var(--padding-xs-4);
}

.print-popup-btns-wrapper {
  display: flex;
  gap: var(--padding-xxm-16);
}

.print-popup-btn-block {
  border: 1px solid var(--main-bg);
  width: 50%;
  height: 30px;
  border-radius: var(--padding-xs-4);
  text-align: center;
  line-height: 26px;
  color: var(--white);
  cursor: pointer;
}

.print-popup-close:hover,
.print-popup-btn-block:hover {
  opacity: 0.7;
}

.print-popup-btn-confirm {
  background-color: var(--blue);
}

.print-popup-btn-confirm-disabled {
  background-color: var(--gray);
  cursor: initial;
}

.print-popup-btn-confirm-disabled:hover {
  opacity: 1;
}

.print-popup-btn-cansel {
  background-color: var(--red);
}

.print-popup-checkbox-wrapper {
  height: 20px;
  cursor: pointer;
}

.print-popup-checkbox-wrapper svg {
  margin-top: 2px;
}

.print-popup-content-wrapper-select-all {
  position: relative;
}

.print-popup-separator {
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: var(--gray);
  bottom: -9px;
  left: 0;
}
