
@media screen and (max-width: 499px) {
  .FieldForm-wrapper {
    width: calc(100% - 16px);
    padding: 0 var(--padding-s-8);
  }
}

@media screen and (max-width: 1024px) {
  .FieldForm-wrapper {
    right: 50%;
    top: 50%;
    transform: translate(50%, -50%);
    -webkit-transform: translate(50%, -50%);
  }
}

