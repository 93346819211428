.OptionsList-wrapper {
  position: relative;
}

.OptionsList-list-wrapper {
  background-color: var(--white);
  border: 1px solid var(--gray);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: absolute;
  right: 0;
  top: 42px;
  width: 136px;
  z-index: 1;
}

.OptionsList-list-content {
  font-size: 14px;
  padding: 4px 8px;
  width: 100%;
  cursor: pointer;
  /* color: var(--main-bg); */
}

.OptionsList-list-content:hover {
  background-color: var(--blue);
  color: var(--white);
}

.OptionsList-icon-wrapper {
  margin-top: 2px;
}
