.SelectedForms-wrapper {
  display: flex;
  flex-wrap: wrap;
  font-size: 13px;
  gap: var(--padding-xs-4);
  background-color: var(--white);
  margin: 0 24px;
}

.SelectedForms-wrapper-el {
  border: 1px solid var(--gray);
  padding: var(--padding-xs-4);
  border-radius: var(--padding-xs-4);
  background-color: var(--white);
  display: flex;
  gap: var(--padding-xs-4);
  color: var(--blue);
}

.SelectedForms-wrapper .baseInput-selected-wrapper-el-close {
  cursor: pointer;
}

.SelectedForms-wrapper .baseInput-selected-wrapper-el-close:hover {
  opacity: 0.7;
}
