.RightMainBlock-wrapper .field-wrapper-1 {
  position: absolute;
  background-color: var(--yellow-bg);
  z-index: 1001;
  height: 100%;
  width:100%;
  cursor: pointer;
}

.RightMainBlock-wrapper .field-wrapper-1 .field-block-checkbox svg {
  position: absolute;
  left: -2px;
  top: -2px;
  /* background-color: var(--light-green); */
  background-color: var(--yellow-bg);
  opacity: 1;
}

.field-tooltip-wrapper {
  display: none;
  position: absolute;
  left: 50%;
  top: -300%;
  transform: translate(-50%, 0%);
  -webkit-transform: translate(-50%, 0%);
  z-index: 1002;
}

.RightMainBlock-wrapper .tooltip-message-wrapper {
  width: 110px;
}

.fieldwrapper:hover .field-tooltip-wrapper {
  display: block;
}

.fieldBlock-text-content {
  /* position: absolute;
  top: -2px */
  line-height: 11pt;
  display: flex;
}

.fieldBlock-text-content-j-l {
  justify-content: left;
}

.fieldBlock-text-content-j-r {
  justify-content: right;
}

.fieldBlock-text-content-j-c {
  justify-content: center;
}

.fieldBlock-textarea-content,
.fieldBlock-input-content {
  width: 100%;
  height: 100%;
  background-color: var(--yellow-bg);
  border: none;
  color: var(--textBlue);
}

.fieldBlock-textarea-content:is(:focus, :active, :visited, :focus-visible),
.fieldBlock-input-content:is(:focus, :active, :visited, :focus-visible) {
  border-color: var(--border-focused) !important;
  outline: none!important;
  box-shadow: none;
  background-color: #FCFBD5 !important;
}