.toggle-switch {
  position: relative;
  display: inline-block;
  width: 70px;
  height: 24px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--lightslaygray-bg);
  transition: 0.4s;
  border-radius: 18px;
  font-size: 10px;
  color: var(--white);
  padding-top: 5px;
}

.switch-slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .switch-slider {
  background-color: var(--blue);
}

input:checked + .switch-slider:before {
  transform: translateX(46px);
}
