.base-input-container {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap:var(--padding-s-8);
}

.base-input-block {
  height: 100%;
  width: 100%;
}

.base-input-container input {
  border-radius: var(--padding-s-8);
  padding: var(--padding-xxm-16) var(--padding-xm-20);
  background-color: var(--white);
}


.base-input-container input:is(:focus, :active, :visited, :focus-visible) {
  border-color: var(--border-focused) !important;
  outline: none!important;
  box-shadow: none;
}

.base-input-container input:read-only {
  background-color: var(--white);
}

.base-input-container input::-webkit-input-placeholder { 
  /* color: var(--border-focused); */
  color: var(--light-blue);
}

.base-input-container input:-ms-input-placeholder {
  color: var(--light-blue);
}

.base-input-container input::placeholder {
  color: var(--light-blue);
}

.base-input-block-required input{
  border: 1px solid var(--red);
}

.base-input-block-default input{
  border: 1px solid var(--border-line);
}

.base-input-container-line input {
  border-radius: 0;
  border: none;
  /* background-color: hsl(207, 28%, 65%, 10%); */
}
