.FieldForm-wrapper {
  position: absolute;
  right: calc(35vw - 199px);
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  width: 400px;
  height: auto;
  background-color: var(--light-green);
  z-index: 2000;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  border-radius: var(--padding-s-8);
  background-color: var(--white);
  border: 1px solid var(--border-line);
}

.FieldForm-wrapper-content {
  position: relative;
}

.FieldForm-content-close {
  position: absolute;
  top: var(--padding-s-8);
  right: var(--padding-s-8);
  cursor: pointer;
}

.FieldForm-content-close:hover {
  opacity: 0.7;
}

.FieldForm-content-form {
  padding: var(--padding-xxl-28);
}

.FieldForm-content-form-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--padding-xm-20);
}

.FieldForm-content-form-input-wrapper {
  position: relative;
  width: 100%;
}

.FieldForm-content-form-error-name {
  position: absolute;
  top: 70px;
  left: 0px;
  font-size: 12px;
  color: var(--red);
}

.FieldForm-content-form-btns-wrapper {
  display: flex;
  width: 100%;
  gap: 16px;
}

.FieldForm-content-form-btns-cancel:hover,
.FieldForm-content-form-btns-submit:hover {
  background-color: var(--light-blue) !important;
  /* border: 1px solid var(--border-line) !important; */
  color: var(--gray) !important;
}

.FieldForm-content-form-btns-cancel {
  background-color: var(--gray) !important;
  border: none !important;
  color: var(--white) !important;
}

.FieldForm-content-form-btns-submit {
  background-color: var(--blue) !important;
  border: none !important;
  color: var(--white) !important;
}

.FieldForm-content-form-btns-wrapper button {
  cursor: pointer;
}

.FieldForm-content-form-btns-wrapper .base-button-container button {
  padding-top: 11px;
  padding-bottom: 12px;
}

.FieldForm-wrapper .base-input-label {
  color: var(--blue);
  font-size: 14px;
}

.FieldForm-wrapper .base-input-container-line input {
  border: 1px solid var(--light-gray) !important;
}

.FieldForm-wrapper input:disabled {
  color: var(--mainBg);
}

.flatpickr-calendar {
  margin-left: 8px;
}

.FieldForm-content-form-title-wrapper {
  color: var(--blue);
  font-size: 16px;
}
