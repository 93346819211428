.Login-wrapper {
  position: absolute;
  width: 400px;
  background-color: var(--light-gray);
  padding: var(--padding-xxl-28) var(--padding-l-40);
  border-radius: var(--padding-s-8);
  border: 1px solid var(--border-line);
  left: 50%;
  top: calc(50% - 20px);
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  z-index: 1054;
}

.Login-wrapper-box {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* .Login-title-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--blue);
} */

.Login-title-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  font-weight: 600;
}

.Login-title-context {
  font-size: 18px;
  line-height: 16px;
}

.Login-title-wrapper img {
  width: 60%;
}

.Login-content-form-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--padding-xm-20);
}

.Login-content-form-error-email {
  position: absolute;
  bottom: 114px;
  left: 0px;
  font-size: 12px;
  color: var(--red);
}
.Login-content-form-error-password {
  position: absolute;
  bottom: 55px;
  left: 0px;
  font-size: 12px;
  color: var(--red);
}

.Login-bottom-text-wrapper {
  position: absolute;
  bottom: -20px;
  right: 0px;
  font-size: 11px;
  color: var(--main-bg);
}
