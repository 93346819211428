.MainPage-header-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  padding: var(--padding-s-8) var(--padding-m-24);
  /* gap: var(--padding-xxm-16); */
  background-color: var(--white);
  /* position: fixed; */
}

.MainPage-header-wrapper-initial {
  display: flex;
  width: 100%;
  height: 100%;
  padding: var(--padding-s-8) var(--padding-m-24);
  /* gap: var(--padding-xxm-16); */
  background-color: var(--white);
  justify-content: center;
  position: relative;
  /* position: fixed; */
}

.MainPage-header-toggleSwitch-wrapper {
  margin-top: 3px;
}

.MainPage-header-wrapper-initial-spiner {
  position: absolute;
  top: 90px;
  z-index: 7;
}

.MainPage-header-left {
  height: 40px;

  display: flex;
  align-items: center;
  color: var(--blue);
  border-top-left-radius: var(--padding-s-8);
  border-bottom: 2px solid var(--blue);
  font-weight: 600;
  font-size: 18px;
  justify-content: space-between;
  z-index: 1010;
}

.MainPage-header-left-init {
  width: calc(100% - 64px);
}

.MainPage-header-left-NotInit {
  width: calc(30% + 8px);
}

.MainPage-header-right-init {
  width: 100px;
}

.MainPage-header-right-NotInit {
  width: 64px;
}

.MainPage-header-right {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: var(--blue);
  border-top-left-radius: var(--padding-s-8);
  border-bottom: 2px solid var(--blue);
  font-weight: 600;
  font-size: 18px;
  gap: var(--padding-s-8);
}

.MainPage-header-arrows-wrapper {
  display: flex;
  flex-direction: column;
}

.MainPage-header-arrow-block-down,
.MainPage-header-arrow-block-up {
  position: relative;
  width: 24px;
  height: 20px;
  cursor: pointer;
}

.MainPage-header-arrow-block-up svg {
  position: absolute;
  top: -1px;
  left: 0px;
}

.MainPage-header-arrow-block-down svg {
  position: absolute;
  top: 6px;
  left: 0px;
}

.MainPage-header-arrow-block-down svg path {
  transform: rotate(180deg);
  transform-origin: 50% 50%;
}

.MainPage-header-left-search-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
}

.MainPage-header-left-search-wrapper .baseInput-ul-wrapper {
  width: calc(100% + 24px) !important;
  z-index: 5;
}

.MainPage-header-icon-wrapper {
  cursor: pointer;
}

.MainPage-header-icon-wrapper-disabled {
  cursor: default;
}

.MainPage-header-icon-OptionsIcon {
  margin-top: 2px;
}

.handleListVisibleTabPress-FormsList {
  border: 1px solid var(--blue);
  background-color: var(--light-blue);
  border-radius: var(--padding-xs-4);
}
