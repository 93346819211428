.LeftTitleBlock-wrapper {
  display: flex;
  flex-direction: column;
}

.LeftTitleBlock-wrapper-gray {
  display: flex;
  flex-direction: column;
  opacity: 0.7;
  border: 1px dotted var(--gray);
}

.LeftTitleBlock-header-wrapper {
  background-color: var(--sub-header-bg);
  height: var(--padding-xxl-28);
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 0 var(--padding-s-8);
  border-radius: var(--padding-xs-4);
}

.LeftTitleBlock-main-wrapper {
  display: flex;
  flex-direction: column;
  padding: var(--padding-xs-4);
  font-size: 12px;
}

.LeftTitleBlock-header-exp-btn {
  cursor: pointer;
  width: var(--padding-xm-20);
  display: flex;
  justify-content: center;
  align-items: center;
}

.LeftTitleBlock-header-exp-btn div {
  padding-bottom: var(--padding-xxs-2);
}

.LeftTitleBlock-header-left {
  display: flex;
  gap: var(--padding-s-8);
  width: 100%;
  /* justify-content: space-between; */
}

.LeftTitleBlock-header-text {
  display: block;
  width: calc(100% - 40px);
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  font-size: 13px;
  height: var(--padding-xm-20);
  line-height: var(--padding-xm-20);
  gap: var(--padding-xs-4);
}

.LeftTitleBlock-list-item {
  padding: var(--padding-xs-4);
  padding-left: var(--padding-xl-32);
  cursor: pointer;
  background-color: var(--white);
  border-radius: var(--padding-xs-4);
}

.LeftTitleBlock-list-item:hover {
  /* background-color: var(--blue); */
  opacity: 0.7;
}

.LeftTitleBlock-list-item-active {
  padding: var(--padding-xs-4);
  padding-left: var(--padding-xl-32);
  cursor: pointer;
  background-color: var(--blue);
  color: var(--white);
}

.LeftTitleBlock-header-wrapper-active {
  border-bottom: 1px solid var(--white);
}

.LeftTitleBlock-main-wrapper-active {
  height: 0;
  padding: 0;
  display: none !important;
}

.LeftTitleBlock-header-wrapper:has(
    .LeftTitleBlock-header-exp-btn-remove:hover
  ) {
  opacity: 0.7;
}

.LeftTitleBlock-header-exp-btn-remove {
  margin-left: auto;
}

.LeftTitleBlock-header-btns-wrapper {
  display: flex;
}

.LeftTitleBlock-header-drag-reorder-btn {
  cursor: grab;
}

.LeftTitleBlock-header-wrapper:has(
    .LeftTitleBlock-header-drag-reorder-btn:hover
  ) {
  border: 1px dotted var(--main-bg);
}

.LeftTitleBlock-header-drag-reorder-btn-draged {
  position: absolute;
  z-index: 10000;
}
