#RightMainBlock-wrapper {
  /* width: calc(100% + 17px); */
  height: 100%;
  overflow-y: scroll;
  padding-right: 17px; /* Increase/decrease this value for cross-browser compatibility */
  box-sizing: content-box; /* So the width will be 100% + 17px */
}

#RightMainBlock-wrapper input:focus {
  border: 1px solid var(--blue) !important;
}

#RightMainBlock-wrapper textarea:focus {
  border: 1px solid var(--blue) !important;
}

.RightMainBlock-wrapper-closed {
  /* width: 0; */
  width: calc(100% + 17px);
  /* -webkit-transition: width 2s;
    -moz-transition: width 2s;
    -o-transition: width 2s;
    transition: width 2s; */
}

.RightMainBlock-wrapper-opened {
  width: calc(100% + 17px);
  /* -webkit-transition: width 2s;
    -moz-transition: width 2s;
    -o-transition: width 2s;
    transition: width 2s; */
}

#RightMainBlock-wrapper img {
  display: block;
  margin: auto;
  border-radius: 2px;
}

#RightMainBlock-wrapper .forms-pdf-viewer {
  position: relative;
  width: 612pt;
  margin: 0 auto;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  /* margin-left: var(--padding-xs-4);
  margin-right: var(--padding-xs-4); */
  margin-top: var(--padding-xs-4);
  margin-bottom: var(--padding-xm-20);
  border-radius: 2px;
}

#RightMainBlock-wrapper .fieldwrapper {
  position: absolute;
  /* background-color: var(--secondYellow); */
  /* opacity: 0.7;*/
  z-index: 999 !important;
  /* top:0 !important;
  left:0 !important */
  /* cursor: pointer; */
}

@media screen and (min-width: 1025px) and (max-width: 1110px) {
  #RightMainBlock-wrapper .forms-pdf-viewer {
    margin-left: var(--padding-xs-4);
    margin-right: var(--padding-xs-4);
  }
}

@media screen and (min-width: 300px) and (max-width: 899px) {
  #RightMainBlock-wrapper .forms-pdf-viewer {
    margin-left: var(--padding-xs-4);
    margin-right: var(--padding-xs-4);
  }
}

@media screen and (max-width: 768px) {
  #RightMainBlock-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
    /* width: 100%; */
  }
  .RightMainBlock-wrapper .forms-pdf-viewer {
    box-sizing: border-box;
    transition: transform 0.3s ease;
  }
}
