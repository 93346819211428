.confirm-message-wrapper {
  position: fixed;
  width: 300px;
  background-color: var(--light-gray);
  border-radius: 10px;
  padding: 40px 15px 25px;
  z-index: 1055;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  border: 3px solid var(--blue);
  display: flex;
  flex-direction: column;
  gap: var(--padding-xxl-28);
}

.confirm-message-close {
  position: absolute;
  right: 12px;
  top: 12px;
  cursor: pointer;
}

.confirm-message-content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.confirm-message-content-header,
.confirm-message-content-text {
  text-align: center;
}

.confirm-message-content-header span {
  font-weight: 600;
  font-size: 18px;
  color: var(--body-text);
}

.confirm-message-content-text span {
  font-weight: 400;
  font-size: 14px;
  line-height: 16.94px;
  color: var(--body-text);
}

.confirm-message-btns-wrapper {
  display: flex;
  gap: var(--padding-xxm-16);
}

.confirm-message-btn-block {
  border: 1px solid var(--main-bg);
  width: 50%;
  height: 30px;
  border-radius: var(--padding-xs-4);
  text-align: center;
  line-height: 26px;
  color: var(--white);
  cursor: pointer;
}

.confirm-message-close:hover,
.confirm-message-btn-block:hover {
  opacity: 0.7;
}

.confirm-message-btn-confirm {
  background-color: var(--blue);
}

.confirm-message-btn-cansel {
  background-color: var(--red);
}
