.base-input-container {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--padding-s-8);
}

.baseInput-ul-wrapper::-webkit-scrollbar {
  width: 16px;
}

.baseInput-ul-wrapper::-webkit-scrollbar-track {
  background-color: var(--light-gray);
  border-radius: 100px;
}

.baseInput-ul-wrapper::-webkit-scrollbar-thumb {
  background-color: var(--gray);
  border: 4px solid transparent;
  border-radius: 100px;
  background-clip: padding-box;
}

.base-input-block {
  height: 100%;
  width: 100%;
}

.base-input-container input {
  border-radius: var(--padding-s-8);
  padding: var(--padding-xxm-16) 108px var(--padding-xxm-16)
    var(--padding-xm-20);
  background-color: var(--white);
}

.base-input-container input:is(:focus, :active, :visited, :focus-visible) {
  border-color: var(--border-focused) !important;
  outline: none !important;
  box-shadow: none;
}

.base-input-container input:read-only {
  background-color: var(--white);
}

.base-input-container input::-webkit-input-placeholder {
  /* color: var(--border-focused); */
  color: var(--light-blue);
}

.base-input-container input:-ms-input-placeholder {
  color: var(--light-blue);
}

.base-input-container input::placeholder {
  color: var(--light-blue);
}

.base-input-block-required input {
  border: 1px solid var(--red);
}

.base-input-block-default input {
  border: 1px solid var(--border-line);
}

.base-input-container-line input {
  border-radius: 0;
  border: none;
  /* background-color: hsl(207, 28%, 65%, 10%); */
}

.base-input-container ul {
  list-style-type: none;
}

.base-input-container ul li p {
  font-size: 14px;
  line-height: 20px;
}

.base-input-container ul li {
  display: flex;
  align-items: center;
  padding: var(--padding-xs-4);
  width: 100%;
}

.base-input-container ul li:hover {
  opacity: 0.7;
  cursor: pointer;
}

.baseInput-ul-wrapper {
  border-radius: var(--padding-s-8);
  padding: var(--padding-xs-4);
  background-color: var(--white);
  z-index: 6;
  position: relative;
}

.baseInput-ul-wrapper-close {
  position: absolute;
  top: 36px;
  left: calc(100vw - 200px);
  z-index: 7;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.baseInput-ul-wrapper-close-no-result {
  position: absolute;
  top: 40px;
  left: calc(100vw - 200px);
  z-index: 7;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.baseInput-ul-wrapper-close-box {
  padding-top: 3px;
}

.baseInput-ul-wrapper-close:hover {
  opacity: 0.7;
}

.baseInput-selected-wrapper-tooltip {
  top: 40px;
  z-index: 7;
  font-size: 13px;
  padding-left: 6px;
  background-color: var(--white);
  position: absolute;
  /* width: calc(100vw - 48px - 40px); */
  /* height: 20px; */
  height: 14px;
  line-height: 14px;
  color: var(--blue);
}

.baseInput-selected-wrapper-tooltip span {
  border-bottom: 1px solid var(--blue);
}

.baseInput-selected-wrapper {
  padding: var(--padding-xs-4) 0px;
  top: 40px;
  display: flex;
  flex-wrap: wrap;
  z-index: 6;
  font-size: 13px;
  gap: var(--padding-xs-4);
  background-color: var(--white);
  position: absolute;
}

.baseInput-error-wrapper {
  padding: var(--padding-xs-4) 0px;
  top: 40px;
  display: flex;
  flex-wrap: wrap;
  z-index: 6;
  font-size: 14px;
  gap: var(--padding-xs-4);
  background-color: var(--white);
  position: absolute;
  color: var(--red);
}

.baseInput-no-result-wrapper {
  padding: var(--padding-xs-4) 0px;
  top: 40px;
  display: flex;
  flex-wrap: wrap;
  z-index: 6;
  font-size: 14px;
  gap: var(--padding-xs-4);
  background-color: var(--white);
  position: absolute;
  color: var(--gray);
  padding-bottom: var(--padding-xs-4);
}

.baseInput-no-result-wrapper-init,
.baseInput-error-wrapper-main {
  width: calc(100% + 24px);
}

.baseInput-selected-wrapper-init {
  width: 100%;
}

.baseInput-error-wrapper-init {
  width: 100%;
}

.baseInput-no-result-wrapper-main,
.baseInput-selected-wrapper-main {
  width: calc(100% + 24px);
}

.baseInput-selected-wrapper-el {
  border: 1px solid var(--gray);
  padding: var(--padding-xs-4);
  border-radius: var(--padding-xs-4);
  background-color: var(--white);
  display: flex;
  gap: var(--padding-xs-4);
}

.baseInput-selected-wrapper-el .baseInput-selected-wrapper-el-close:hover {
  cursor: pointer;
}

.baseInput-selected-wrapper-el:has(.baseInput-selected-wrapper-el-close:hover) {
  opacity: 0.7;
}

.baseInput-Remove-Btn-wrapper,
.baseInput-Send-Btn-wrapper {
  position: absolute;
  top: 5px;
  z-index: 5;
  right: 0px;
  font-size: 14px;
  padding: var(--padding-xs-4);
  border-radius: var(--padding-xs-4);
}

.baseInput-Send-Btn-wrapper {
  right: 48px;
}

.baseInput-Remove-Btn-wrapper-active,
.baseInput-Send-Btn-wrapper-active {
  border: 1px solid var(--yellow);
  cursor: pointer;
}

.baseInput-Remove-Btn-wrapper-disabled,
.baseInput-Send-Btn-wrapper-disabled {
  border: 1px solid var(--gray);
  opacity: 0.7;
  color: var(--gray);
}

.baseInput-Send-Btn-wrapper:hover {
  opacity: 0.7;
}

.baseInput-ul-wrapper-spiner {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px 0;
  position: absolute;
  top: 100px;
  z-index: 7;
}

/* --padding-l-40: 40px;
--padding-m-24l-36: 36px;
--padding-xl-32: 32px;
--padding-xxl-28: 28px;
--padding-m-24: 24px;
--padding-xm-20: 20px;
--padding-xxm-16: 16px;
--padding-xxxm-12: 12px;
--padding-s-8: 8px;
--padding-xs-4: 4px;
--padding-xxs-2: 2px; */
