@media screen and (min-width: 1025px) {
  .MainBlock-wrapper .MainBlock-wrapper-left-open-btn {
    display: none;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .MainBlock-wrapper {
    /* height: calc(100vh - 182px); */
    height: calc(100vh - 112px);
  }

  .MainBlock-wrapper .MainBlock-wrapper-left-closed {
    width: 24px;
    -webkit-transition: left 2s, width 2s;
    -moz-transition: left 2s, width 2s;
    -o-transition: left 2s, width 2s;
    transition: left 2s, width 2s;
  }

  .MainBlock-wrapper .MainBlock-wrapper-left-opened {
    width: calc(100% - 48px);
    -webkit-transition: left 2s, width 2s;
    -moz-transition: left 2s, width 2s;
    -o-transition: left 2s, width 2s;
    transition: left 2s, width 2s;
  }

  .MainBlock-wrapper-right-narrow,
  .MainBlock-wrapper-right {
    width: calc(100% - 18px);
    margin-left: 24px;
  }

  .MainBlock-wrapper-right-narrow .forms-pdf-viewer,
  .MainBlock-wrapper-right .forms-pdf-viewer {
    margin: 0 auto;
    /* padding-left: 8px; */
  }

  .MainBlock-wrapper .MainBlock-wrapper-left-open-btn {
    display: block;
  }
}

@media screen and (max-width: 768px) {
  .LeftMainBlock-wrapper {
    display: none;
  }

  .MainBlock-wrapper-right-narrow,
  .MainBlock-wrapper-right {
    width: 100%;
    margin-left: 0px;
  }

  .MainBlock-wrapper {
    /* height: calc(100vh - 182px); */
    height: calc(100vh - 112px);
  }

  .MainBlock-wrapper .MainBlock-wrapper-left-closed {
    width: 24px;
    -webkit-transition: left 2s, width 2s;
    -moz-transition: left 2s, width 2s;
    -o-transition: left 2s, width 2s;
    transition: left 2s, width 2s;
  }

  .MainBlock-wrapper .MainBlock-wrapper-left-opened {
    width: calc(100% - 48px);
    -webkit-transition: left 2s, width 2s;
    -moz-transition: left 2s, width 2s;
    -o-transition: left 2s, width 2s;
    transition: left 2s, width 2s;
  }

  /* .MainBlock-wrapper-right-narrow,
  .MainBlock-wrapper-right {
    width: calc(100% - 18px);
    margin-left: 24px;
  } */
}
