.base-button-container {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap:var(--padding-s-8);
}

.base-button-block {
  height: 100%;
  width: 100%;
}

.base-button-container button {
  border-radius: var(--padding-s-8);
  border: 1px solid var(--border-line);
  padding: var(--padding-xxm-16) var(--padding-xm-20);
  background-color: var(--blue);
  color: var(--white);
}

.base-button-container button:disabled {
  background-color: var(--light-blue100);
}