.DatePicker-block-wrapper {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 62px;
  right: 36px;
  z-index: 1;
}
